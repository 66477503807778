import { TableCellColor } from '../../ui/models/table-cell-color';

export type Promotion = {
  id: string;
  name: string;
  type: string;
  prizes: string;
  prizeDescription: string;
  country: string;
  startDate: Date;
  endDate: Date;
  expirationDate: Date;
  auditDueDate: Date;
  agencyStatus: string;
  auditStatus: AuditStatus;
  auditStatusLabel: string;
  promotionStatus: PromotionStatus;
  promotionStatusLabel: string;
  updatedAt: Date;
  promotionUrl: string | null;
  prizesDescription: string | null;
  cdsPromoId: string | null;
  promotionRegulation?: PromotionRegulation[];
  promotionMechanic?: PromotionMechanic;
  timeZone?: string;
};

export type PromotionRegulation = {
  id: number;
  container: string;
  blobName: string;
  createdAt: Date;
};

export type PromotionMechanic = {
  id: string;
  name: string;
  translationKey: string;
};

export type UpdatePromotionUrl = {
  promotionId: string;
  promotionUrl: string | null;
};

export type UpdatePromotionPrizes = {
  promotionId: string;
  promotionPrizes: string | null;
};

export type UpdateCDSPromotionId = {
  promotionId: string;
  cdsPromoId: string | null;
};

export enum AuditStatus {
  UPCOMING = 'UPCOMING',
  READY = 'READY',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum PromotionStatus {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export const AuditStatusColors: Record<AuditStatus, TableCellColor> = {
  UPCOMING: TableCellColor.yellow,
  ACTIVE: TableCellColor.green,
  READY: TableCellColor.blue,
  CLOSED: TableCellColor.red,
};

export const PromotionStatusColors: Record<PromotionStatus, TableCellColor> = {
  UPCOMING: TableCellColor.yellow,
  ACTIVE: TableCellColor.green,
  CLOSED: TableCellColor.red,
};
export interface PromotionSummary {
  [key: number]: number;
}

export interface userPromotionNote {
  id: string;
  text: string;
  promotionId: string;
  userId: string;
}

export interface userPromotionComment {
  id: string;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  participationId: string;
}