import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-logged-out-page',
  templateUrl: './base-logged-out-page.component.html',
  styleUrl: './base-logged-out-page.component.scss'
})
export class BaseLoggedOutPageComponent {
  @Input() title: string = '';
}
